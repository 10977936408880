'use client';
import { Suspense } from 'react';
import { AuthContextProvider } from '@/context/authContext';
export default function RootLayout({ children, modal }) {
  return (
    <Suspense>
      <AuthContextProvider>
        <>{children}</>
        <div>{modal}</div>
      </AuthContextProvider>
    </Suspense>
  );
}
